.top-bar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid var(--bs-border-color); }
  .top-bar .container-fluid {
    padding-right: 1.8rem;
    padding-left: 1rem; }
  .top-bar .navbar {
    margin: 0 auto;
    padding-top: .2rem;
    padding-bottom: .2rem; }
  .top-bar .navbar-nav {
    margin-right: -1rem;
    align-items: center; }
    .top-bar .navbar-nav > .nav-item, .top-bar .navbar-nav > .dropdown {
      margin-left: 1rem; }
  .top-bar .navbar-nav > .nav-item > .nav-link {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    color: var(--bs-link-color); }
    .top-bar .navbar-nav > .nav-item > .nav-link.active {
      color: var(--bs-nav-link-color); }
  .top-bar .dropdown-menu {
    top: 2.9rem; }
    .top-bar .dropdown-menu .dropdown-item {
      padding: 0; }
    .top-bar .dropdown-menu .btn-link, .top-bar .dropdown-menu .nav-link, .top-bar .dropdown-menu .nav-link:active, .top-bar .dropdown-menu .nav-link.active {
      color: var(--bs-body-color);
      padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x) !important; }
  .top-bar .dropdown-menu-end {
    position: absolute !important;
    inset: 0px 0px auto auto;
    transform: translate(0px, 38px); }
  .top-bar .navbar-brand {
    margin-right: 0;
    padding: 0;
    line-height: 1; }
    .top-bar .navbar-brand img {
      max-height: 20px; }
