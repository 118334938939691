.triggers-table tbody td span {
  border-bottom: 1px dashed #A1A6B4;
  cursor: pointer; }

.trigger-set-main {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1), 0 2px 18px rgba(0, 0, 0, 0.1); }
  .trigger-set-main > div.mb-3 {
    flex: 1 0 0; }
    .trigger-set-main > div.mb-3:first-child {
      flex: 1 0 25%; }
    .trigger-set-main > div.mb-3 .form-label {
      text-wrap: nowrap; }

@media screen and (max-width: 825px) {
  .trigger-set-main {
    flex-wrap: wrap; }
    .trigger-set-main > div.mb-3 {
      flex: 0 0 30%;
      margin-bottom: 0 !important; } }

@media screen and (max-width: 650px) {
  .trigger-set-main > div.mb-3:first-child {
    flex: 1 0 50%; } }

@media screen and (max-width: 470px) {
  .main-content.view-triggers .page-heading {
    flex-direction: row;
    align-items: center; }
    .main-content.view-triggers .page-heading .page-title {
      margin-bottom: 0; }
    .main-content.view-triggers .page-heading-controls {
      width: auto; } }

@media screen and (max-width: 460px) {
  .triggers-tab .nav-item {
    width: 100%; }
  .triggers-tab.nav-tabs .nav-link {
    margin-right: 0; } }
