.admin-top-controls {
  display: flex;
  gap: 1rem; }

.admin-reports-item {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1.3rem;
  border: 1px solid #ECEEF4;
  border-radius: .5rem; }

.admin-reports-controls {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem; }

.admin-reports-header {
  margin-bottom: .5rem; }

.admin-reports-footer {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-top: .5rem;
  color: rgba(60, 64, 76, 0.5);
  font-size: .8rem; }

.admin-reports-title {
  display: inline;
  font-size: .95rem;
  font-weight: 500; }

.admin-reports-description {
  margin-bottom: 0; }

.admin-reports-type {
  margin-left: .5rem; }
