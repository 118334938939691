.metrics {
  display: flex;
  justify-content: space-between; }
  .metrics-box {
    min-width: 11.5625rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: .5rem;
    background-color: #fff;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1), 0 2px 18px rgba(0, 0, 0, 0.1); }
    .metrics-box-text {
      font-size: 2rem; }
    .metrics-box-title {
      display: block;
      font-weight: normal;
      font-size: 0.875rem; }
    .metrics-box .icon {
      width: 1.875rem;
      height: 1.875rem;
      background-color: #00943E; }

@media screen and (max-width: 1070px) {
  .metrics-box {
    min-width: auto; } }

@media screen and (max-width: 860px) {
  .metrics {
    flex-wrap: wrap;
    gap: .8rem; }
    .metrics-box {
      flex: 1 0 49%; }
      .metrics-box-text {
        display: flex;
        gap: .5rem;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 0; }
      .metrics-box-title {
        line-height: 2; } }

@media screen and (max-width: 750px) {
  .metrics-box {
    flex: 1 0 32%; }
    .metrics-box-text {
      font-size: 1.625rem; } }

@media screen and (max-width: 630px) {
  .metrics {
    flex-direction: column; }
    .metrics-box-text {
      font-size: 1.25rem;
      line-height: 1.2; } }
