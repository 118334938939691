.dialog {
  --bs-modal-width: 24.375rem; }
  .dialog .modal-content {
    border-top: 3px solid #000; }
  .dialog-icon {
    flex: 0 0 auto;
    width: 3.125rem;
    height: 3.125rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 50%; }
    .dialog-icon .icon {
      width: 1.5rem;
      height: 1.5rem;
      background-color: #fff; }
  .dialog-title {
    margin-bottom: 0.5rem;
    font-size: 1rem; }
  .dialog-message {
    margin-bottom: 0; }
  .dialog .modal-body {
    display: flex;
    gap: 1rem;
    padding: 1.5rem; }
  .dialog .modal-footer {
    padding: 0.75rem;
    border-top: 0;
    justify-content: center; }
  .dialog-success .modal-content {
    border-color: #00C854; }
  .dialog-success .dialog-icon {
    background-color: #00C854; }
  .dialog-error .modal-content, .dialog-delete .modal-content {
    border-color: #FF7158; }
  .dialog-error .dialog-icon, .dialog-delete .dialog-icon {
    background-color: #FF7158; }
  .dialog-info .modal-content {
    border-color: #3D5FC0; }
  .dialog-info .dialog-icon {
    background-color: #3D5FC0; }
