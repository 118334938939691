.left-navigation {
  width: 12.5rem;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0rem 1rem 1rem 1rem;
  height: 100%;
  border-right: 1px solid var(--bs-border-color);
  background-color: #fff; }
  @media screen and (max-width: 1690px) {
    .left-navigation {
      border-left: 0; } }
  .left-navigation .nav-link {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    font-weight: normal; }
    .left-navigation .nav-link .icon {
      margin-right: .6rem;
      background-color: var(--bs-nav-link-color);
      transition: color 0.15s ease-in-out,  background-color 0.15s ease-in-out,  border-color 0.15s ease-in-out; }
    .left-navigation .nav-link:hover .icon {
      background-color: var(--bs-nav-link-hover-color); }
    .left-navigation .nav-link.active {
      color: var(--bs-link-hover-color); }
      .left-navigation .nav-link.active .icon {
        background-color: var(--bs-link-hover-color); }
    .left-navigation .nav-link .badge {
      margin-left: .3rem; }
    .left-navigation .nav-link.disabled .icon {
      background-color: var(--bs-nav-link-disabled-color); }

@media screen and (max-width: 1000px) {
  .left-navigation {
    width: auto; }
    .left-navigation .nav-link > .icon {
      margin-right: 0; }
      .left-navigation .nav-link > .icon + span {
        display: none; } }
