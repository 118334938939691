.plan-item {
  background-color: #fff; }
  .plan-item.active {
    background-color: #F4FBF7; }

@media screen and (max-width: 500px) {
  .plan-item .card-body {
    flex-direction: column; }
  .plan-item-action {
    margin-top: .5rem; } }

@media screen and (max-width: 445px) {
  .view-settings .page-content > .nav-tabs .nav-item {
    width: 100%; }
  .view-settings .page-content > .nav-tabs .nav-link {
    margin-right: 0; } }
