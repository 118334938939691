.features-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 0.9375rem; }
  .features-list li {
    display: flex;
    gap: .5rem;
    margin: 1rem 0; }
    .features-list li::before {
      content: "";
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.16' d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z' fill='%2300C854'/%3E%3Cpath d='M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z' stroke='%2300C854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='m15 10-4 4-2-2' stroke='%2300C854' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center; }
